import Button from '@/atoms/Button';
import { useCmsData } from 'utils/cms';
import getCtaProps from 'utils/cms/getCtaProps';
import { CMSCallToActionType } from 'utils/cms/types';
import { trackEvent } from 'utils/tracking';

type WidgetCTAProps = {
  cta: CMSCallToActionType;
  widgetId: string;
  className?: string;
};

const WidgetCTA = ({ cta, className, widgetId }: WidgetCTAProps) => {
  const { slug } = useCmsData();

  const onClick = () => {
    trackEvent(slug, `${widgetId}_cta`, cta.link?.title, cta.link?.url);
  };

  return (
    <Button className={className} onClick={onClick} {...getCtaProps(cta)}>
      {cta.link?.title}
    </Button>
  );
};

export default WidgetCTA;
