import React, { PropsWithChildren } from 'react';

const H4Renderer = ({
  children,
  ...props
}: PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>>) => (
  <h4 className="title-4" {...props}>
    {children}
  </h4>
);

export default H4Renderer;
