import React, { PropsWithChildren } from 'react';

const H2Renderer = ({
  children,
  ...props
}: PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>>) => (
  <h2 className="title-2" {...props}>
    {children}
  </h2>
);

export default H2Renderer;
