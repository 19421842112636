import clx from 'classnames';
import { easeInOut, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';
import FeaturedIcon, { FEATURED_ICON_VARIANTS } from '@/atoms/FeaturedIcon';
import { SIZE, ALIGNMENT_ENUM } from 'types/utils';

type MainTitleProps = {
  icon?: string;
  iconVariant?: FEATURED_ICON_VARIANTS;
  subtitle?: string;
  className?: string;
  size?: SIZE;
  alignment?: ALIGNMENT_ENUM;
  showLine?: boolean;
  isWhite?: boolean;
};

const VARIANT_TO_TITLE_CLASSNAME = {
  [SIZE.SM]: 'title-4',
  [SIZE.MD]: 'title-2',
  [SIZE.LG]: 'uppercase title-1',
};

const VARIANT_TO_SUBTITLE_CLASSNAME = {
  [SIZE.SM]: 'lg:md-regular',
  [SIZE.MD]: 'lg:md-medium',
  [SIZE.LG]: 'lg:lg-bold-caps',
};

const ALIGNMENT_TO_CLASSNAME = {
  left: 'items-start text-left',
  center: 'items-center text-center',
  right: 'items-end text-right',
};

const MainTitle = ({
  children,
  subtitle,
  icon,
  iconVariant,
  size = SIZE.LG,
  alignment = ALIGNMENT_ENUM.left,
  showLine = false,
  className,
  isWhite = false,
}: PropsWithChildren<MainTitleProps>) => (
  <div className={clx('flex flex-col gap-2 w-full', ALIGNMENT_TO_CLASSNAME[alignment], className)}>
    {icon && <FeaturedIcon icon={icon} variant={iconVariant} />}
    <hgroup className="flex flex-col gap-2">
      {subtitle && (
        <p
          className={clx(
            isWhite ? 'text-neutral-0 text-shadow-lg shadow-black' : 'text-neutral-400',
            'sm-bold-caps',
            VARIANT_TO_SUBTITLE_CLASSNAME[size],
          )}
        >
          {subtitle}
        </p>
      )}
      <h1
        className={clx(
          isWhite ? 'text-neutral-0 text-shadow-lg shadow-black' : 'text-neutral-900',
          VARIANT_TO_TITLE_CLASSNAME[size],
        )}
      >
        {children}
      </h1>
    </hgroup>
    {showLine && (
      <motion.span
        animate={{ width: '100%' }}
        className="bg-neutral-30 h-px"
        initial={{ width: 0 }}
        transition={{ duration: 0.3, ease: easeInOut, delay: 1 }}
      />
    )}
  </div>
);

export default MainTitle;
