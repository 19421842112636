import React, { PropsWithChildren } from 'react';

const H5Renderer = ({
  children,
  ...props
}: PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>>) => (
  <h5 className="title-5" {...props}>
    {children}
  </h5>
);

export default H5Renderer;
