import ReactMarkdown from 'react-markdown';

import ImageRenderer from '@/molecules/CustomMarkdown/components/ImageRenderer';
import ListRenderer from '@/molecules/CustomMarkdown/components/ListRenderer';
import StrongRenderer from '@/molecules/CustomMarkdown/components/StrongRenderer';
import H1Renderer from './components/H1Renderer';
import H2Renderer from './components/H2Renderer';
import H3Renderer from './components/H3Renderer';
import H4Renderer from './components/H4Renderer';
import H5Renderer from './components/H5Renderer';
import H6Renderer from './components/H6Renderer';
import LinkRenderer from './components/LinkRenderer';
import ParagraphRenderer from './components/ParagraphRenderer';
import BlockquoteRenderer from './components/QuoteRenderer';

type CustomMarkdownProps = { className?: string; children: string };

const CustomMarkdown = ({ children, className }: CustomMarkdownProps) => (
  <ReactMarkdown
    className={className}
    components={{
      a: LinkRenderer,
      blockquote: BlockquoteRenderer,
      p: ParagraphRenderer,
      h1: H1Renderer,
      h2: H2Renderer,
      h3: H3Renderer,
      h4: H4Renderer,
      h5: H5Renderer,
      h6: H6Renderer,
      strong: StrongRenderer,
      ul: ListRenderer,
      img: ImageRenderer,
    }}
  >
    {children}
  </ReactMarkdown>
);

export default CustomMarkdown;
