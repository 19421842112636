import React, { PropsWithChildren } from 'react';

const H1Renderer = ({
  children,
  ...props
}: PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>>) => (
  <h1 className="title-1" {...props}>
    {children}
  </h1>
);

export default H1Renderer;
