import React, { PropsWithChildren } from 'react';

const StrongRenderer = ({
  children,
  ...props
}: PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>>) => (
  <strong className="font-bold" {...props}>
    {children}
  </strong>
);

export default StrongRenderer;
