import Link from 'next/link';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import ERROR_ROUTES from 'constants/routes';

const LinkRenderer = ({ children, href }: PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>) => {
  if (!href) {
    return <span>{children}</span>;
  }

  const linkProps: AnchorHTMLAttributes<HTMLAnchorElement> = { href };

  if (href.includes('_blank')) {
    linkProps.rel = 'noopener noreferrer';
    linkProps.target = '_blank';
    linkProps.href = href.replace('_blank', '');
  }

  if (href[0] === '/') {
    return (
      <Link href={linkProps.href || ERROR_ROUTES.NOT_FOUND} {...linkProps} className="text-system-info-400">
        {children}
      </Link>
    );
  }

  return (
    <a {...linkProps} className="text-system-info-400">
      {children}
    </a>
  );
};

export default LinkRenderer;
