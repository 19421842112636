import React, { PropsWithChildren } from 'react';

const H3Renderer = ({
  children,
  ...props
}: PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>>) => (
  <h3 className="title-3" {...props}>
    {children}
  </h3>
);

export default H3Renderer;
