import { ButtonOrLinkProps } from '@/atoms/ButtonOrLink/ButtonOrLink';
import { CMSCallToActionType } from './types';
import { BUTTON_VARIANTS, ButtonProps } from '@/atoms/Button';

const getCtaProps = (cta?: CMSCallToActionType) => {
  const disabledButtonProps: ButtonOrLinkProps = {
    disabled: cta?.disabled,
    children: cta?.link?.title,
  };
  const linkProps: ButtonProps = {
    children: cta?.link?.title,
    href: cta?.link?.url || '',
    variant: cta?.variant as BUTTON_VARIANTS,
  };

  if (cta?.disabled) {
    return disabledButtonProps;
  }
  if (cta?.link) {
    return linkProps;
  }

  return undefined;
};

export default getCtaProps;
