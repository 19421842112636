import clx from 'classnames';
import CustomMarkdown from '@/molecules/CustomMarkdown';
import MainTitle from '@/molecules/titles/MainTitle';
import SecondaryTitle from '@/molecules/titles/SecondaryTitle';
import WidgetCTA from 'components/widgets/components/WidgetCTA';
import { ALIGNMENT_ENUM } from 'types/utils';
import { CMSCallToActionType, CMSTitle } from 'utils/cms/types';

type ContentBlockProps = {
  title?: CMSTitle;
  secondary_title?: CMSTitle;
  text?: string;
  alignment: ALIGNMENT_ENUM;
  cta?: CMSCallToActionType;
  id: string;
};

const ALIGNMENT_TO_CLASSNAME = {
  left: 'items-start text-left',
  center: 'items-center text-center',
  right: 'items-end text-right',
};
const ContentBlock = (props: ContentBlockProps) => {
  const { title, secondary_title: secondaryTitle, text, alignment, cta, id } = props;

  return (
    <div className={clx('flex flex-col gap-4', ALIGNMENT_TO_CLASSNAME[alignment])}>
      {title?.main_title && (
        <MainTitle
          {...title}
          alignment={alignment}
          iconVariant={title.icon_variant}
          showLine={title.show_line}
          size={title.title_size}
        >
          {title.main_title}
        </MainTitle>
      )}
      {secondaryTitle?.main_title && (
        <SecondaryTitle
          {...secondaryTitle}
          iconVariant={secondaryTitle.icon_variant}
          showLine={secondaryTitle.show_line}
        >
          {secondaryTitle.main_title}
        </SecondaryTitle>
      )}
      {text && (
        <CustomMarkdown
          className={clx('md-medium md:lg-medium text-neutral-800 flex flex-col', ALIGNMENT_TO_CLASSNAME[alignment])}
        >
          {text}
        </CustomMarkdown>
      )}
      {cta?.link && <WidgetCTA cta={cta} widgetId={id} />}
    </div>
  );
};
export default ContentBlock;
