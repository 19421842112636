import React, { PropsWithChildren } from 'react';

const H6Renderer = ({
  children,
  ...props
}: PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>>) => (
  <h6 className="title-6" {...props}>
    {children}
  </h6>
);

export default H6Renderer;
